import { readingTime } from 'reading-time-estimator';
import striptags from 'striptags';
export const readingTimeText = (text) => {
    return readingTime(striptags(text));
}

/**
 * @param queryString {String}
 * @return String
 */
export const getUtmParamsString = function (queryString) {
    const searchParams = new URLSearchParams(queryString);
    [...searchParams.keys()].map(key => key.startsWith('utm_') || searchParams.delete(key));
    return searchParams.toString();
}